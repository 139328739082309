import { z } from "zod";

import { HydraEnum } from "@/TS/Enums/global_enums";

export const DepartmentSchema = z.object({
  "@id": z.string(),
  "@type": z.string(),
  code: z.string(),
  name: z.string(),
  slug: z.string(),
  countryCode: z.string(),
});

export const DepartmentResponseSchema = z.object({
  [HydraEnum.LIST]: z.array(DepartmentSchema),
});

export type Department = z.infer<typeof DepartmentSchema>;
