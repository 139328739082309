import { useDepartments } from "@/queries/department/department-queries";
import { useContactTopics } from "@/queries/helpdesk/helpdesk-queries";

export function useContactForm() {
  const topicsQuery = useContactTopics();
  const departmentsQuery = useDepartments();

  return {
    topicsQuery,
    departmentsQuery,
  };
}
