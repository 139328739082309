import { useQuery } from "@tanstack/react-query";

import { queryKeys } from "@/queries/query-keys";
import { helpdeskApi } from "@/services/api/internal/helpdesk/helpdesk-api";
import { ContactTopicRead } from "@/TS/Interfaces/global_interfaces";

export function useContactTopics() {
  return useQuery<ContactTopicRead[]>({
    queryKey: queryKeys.topics,
    queryFn: async () => {
      const result = await helpdeskApi.getPublicContactTopics();
      return result;
    },
    staleTime: 3600000,
  });
}
