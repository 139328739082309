import { z } from "zod";

import { HydraEnum } from "@/TS/Enums/global_enums";

export const ContactTopicSchema = z.object({
  "@id": z.string(),
  "@type": z.string(),
  label: z.string(),
});

export const ContactTopicResponseSchema = z.object({
  [HydraEnum.LIST]: z.array(ContactTopicSchema),
});

export type ContactTopicRead = z.infer<typeof ContactTopicSchema>;
