import { useQuery } from "@tanstack/react-query";

import { queryKeys } from "@/queries/query-keys";
import { departmentApi } from "@/services/api/internal/department/department-api";
import { DepartmentRead } from "@/TS/Interfaces/global_interfaces";

export function useDepartments() {
  return useQuery<DepartmentRead[]>({
    queryKey: queryKeys.departments,
    queryFn: async () => {
      const result = await departmentApi.getDepartments();
      return result;
    },
    staleTime: 3600000,
  });
}
