import { z } from "zod";

import {
  Department,
  DepartmentResponseSchema,
} from "@/services/api/internal/department/department-schema";
import { internalRoutes } from "@/services/api/internal/internal-routes";
import { HydraEnum } from "@/TS/Enums/global_enums";
import { CustomError } from "@/utils/init-globals";

export const departmentApi = {
  getDepartments: async (): Promise<Department[]> => {
    try {
      const response = await fetch(internalRoutes.departments);

      if (!response.ok) {
        throw new CustomError(
          "Une erreur est survenue, impossible de récupérer les départements",
        );
      }

      const rawData = await response.json();

      const validatedData = DepartmentResponseSchema.parse(rawData);
      return validatedData[HydraEnum.LIST];
    } catch (error) {
      if (error instanceof z.ZodError) {
        const firstError = error.errors[0];
        const path = firstError.path.join(".");
        const value =
          "received" in firstError
            ? JSON.stringify(firstError.received)
            : "unknown";

        console.error(`Erreur de validation Zod:
          - Champ: ${path}
          - Problème: ${firstError.message}
          - Valeur reçue: ${value}
          (${error.errors.length - 1} autres erreurs similaires)`);

        throw new CustomError(
          `Erreur de format: ${path} invalide (${firstError.message})`,
        );
      }
      throw error;
    }
  },
};
